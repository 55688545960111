import { Box, Button, Grid, Link, Paper, Stack, Typography, useMediaQuery } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';

// Project imports
import AuthFooter from 'components/AuthFooter';
import InlineMessage from 'components/InlineMessage';
import PreviewLabel from 'components/PreviewLabel';

const PageWrapper = styled('div')(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.background.default : theme.palette.primary.light,
    minHeight: '100vh',
}));

const RedirectPage = () => {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

    const [secondsLeft, setSecondsLeft] = useState(15);

    const redirectToNewPad = () => {
        window.location.href = `https://cta-pad.com${window.location.pathname}`;
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setSecondsLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(timer);
                    redirectToNewPad();
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => clearInterval(timer); // Cleanup on component unmount
    }, []);

    return (
        <PageWrapper>
            <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
                <Grid item xs={12}>
                    <Grid
                        container
                        justifyContent="center"
                        alignItems="center"
                        sx={{ minHeight: 'calc(100vh - 68px)' }}
                    >
                        <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
                            <Paper
                                square
                                sx={{
                                    'maxWidth': { xs: 400, lg: 450 },
                                    'margin': { xs: 2.5, md: 3 },
                                    '& > *': {
                                        flexGrow: 1,
                                        flexBasis: '50%',
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        bgcolor: 'primary.main',
                                        color: 'primary.light',
                                        px: 2,
                                        py: 3,
                                        textAlign: 'center',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mb: 0.5,
                                        }}
                                    >
                                        <Typography variant="h1" color="inherit" sx={{ fontWeight: 600 }}>
                                            PAD
                                        </Typography>
                                        <PreviewLabel />
                                    </Box>

                                    <Typography variant="h4" color="inherit" sx={{ fontWeight: 500 }}>
                                        From Community Tech Alliance
                                    </Typography>
                                </Box>
                                <Box sx={{ p: { xs: 2, sm: 3, lg: 4, xl: 5 } }}>
                                    <Grid container spacing={2} alignItems="center" justifyContent="center">
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction={matchDownSM ? 'column-reverse' : 'row'}
                                                alignItems="center"
                                                justifyContent="center"
                                            >
                                                <Grid item>
                                                    <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                        <Typography
                                                            variant="caption"
                                                            fontSize="16px"
                                                            color="inherit"
                                                            textAlign={'center'}
                                                        >
                                                            <b>We&apos;re moving!</b>
                                                            <br /> PAD now lives at{' '}
                                                            <Link href="https://cta-pad.com">https://cta-pad.com</Link>
                                                        </Typography>
                                                    </Stack>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid container direction="column" justifyContent="center" spacing={2}>
                                                <Grid item xs={12}>
                                                    <Button
                                                        disableElevation
                                                        fullWidth
                                                        onClick={redirectToNewPad}
                                                        size="large"
                                                        variant="outlined"
                                                        sx={{
                                                            'display': 'flex',
                                                            'alignItems': 'center',
                                                            'color': 'inherit',
                                                            'backgroundColor':
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark.main
                                                                    : theme.palette.grey[50],
                                                            'borderColor':
                                                                theme.palette.mode === 'dark'
                                                                    ? theme.palette.dark
                                                                    : theme.palette.primary.dark,
                                                            'mb': 2,
                                                            '&:hover': {
                                                                backgroundColor: theme.palette.grey[200],
                                                            },
                                                        }}
                                                    >
                                                        👉 Head to new PAD! 👉
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <InlineMessage
                                            message={`Redirecting in ${secondsLeft} seconds...`}
                                            type="info"
                                        />
                                    </Grid>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sx={{ m: 3, mt: 1 }}>
                    <AuthFooter />
                </Grid>
            </Grid>
        </PageWrapper>
    );
};

export default RedirectPage;
