import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { SnackbarProvider, closeSnackbar } from 'notistack';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// Project imports
import FullScreenLoader from 'components/FullScreenLoader';
import { ConfigProvider } from 'contexts/ConfigContext';
import { LayoutProvider } from 'contexts/LayoutContext';
import ThemeCustomization from 'themes';
import RedirectPage from 'views/RedirectPage';

const router = createBrowserRouter([
    {
        path: '/',
        id: 'root',
        element: <RedirectPage />,
        children: [
            {
                path: '*', // Wildcard route to catch all paths
                element: <RedirectPage />,
            },
        ],
    },
]);

export default function App() {
    return (
        <ConfigProvider>
            <ThemeCustomization>
                <LayoutProvider>
                    <SnackbarProvider
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        action={(snackbarId) => (
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => closeSnackbar(snackbarId)}
                            >
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        )}
                    >
                        <RouterProvider router={router} fallbackElement={<FullScreenLoader />} />
                    </SnackbarProvider>
                </LayoutProvider>
            </ThemeCustomization>
        </ConfigProvider>
    );
}
